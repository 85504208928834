import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {path: '/',name: 'home', component: () => import('../views/NewHomeView.vue'), meta:{title:'Home',htmlAttrs: {amp: true},index: 1}},
  {path: '/about', name: 'about', component: () => import('../views/AboutView.vue'), meta:{title:'About' ,htmlAttrs: {amp: true},index: 2}},
  {path: '/test', name: 'test', component: () => import('../views/TestView.vue'), meta:{title:'Test' ,htmlAttrs: {amp: true},index: 3}},
  {path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('../views/NotFoundView.vue'), meta:{title:'404' ,htmlAttrs: {amp: true},index: 0}},
]

const router = createRouter({history: createWebHistory(), routes})

export default router
