import Vue from 'vue';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faPhone)

const metaManager = createMetaManager();

const messages = {
    en: require('./i18n/en'),
    ja: require('./i18n/ja'),
    'zh-hans': require('./i18n/zh-hans'),
    'zh-hant': require('./i18n/zh-hant'),
};
function getLanguage() {
    const language = navigator.language.toLowerCase();
    if (language === 'ja') {return 'ja';}
    else if (language === 'zh'||'zh-cn'||'zh-sg') {return 'zh-hans';}
    else if (language === 'zh-tw'||'zh-hk') {return 'zh-hant';}
    else {return 'en';}
};
const i18n = createI18n({
    locale: getLanguage(),
    messages
})

const app = createApp(App)

app.use(router)
app.use(i18n)
app.use(metaPlugin)
app.use(createMetaManager())
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
