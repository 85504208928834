<template>
  <div>
      <nav>
        <a href="/" style="text-decoration:none"><span id="title">BanG Dream!萌え</span></a>
        <span id="navilink">
          <router-link to="/">{{ $t("message.homeNavi") }}</router-link> |
          <router-link to="/about">{{ $t("message.aboutNavi") }}</router-link>
        </span>
      </nav>
    <div class="container" id="app">
      <router-view v-slot="{ Component }">
        <transition :name="transitionName" mode="out-in">
          <keep-alive>
            <component :is="Component"/>
          </keep-alive>
        </transition>
      </router-view>
    </div>
  </div>
  <Background/>
</template>
<script>
import Background from '@/components/Background.vue';
import { useMeta } from 'vue-meta';
export default {
  data(){
    return {
      transitionName: '',
    }
  },
  watch: {
    //使用watch 监听$router的变化
    $route(to, from) {
      //如果to索引大于from索引,判断为前进状态,反之则为后退状态
      //console.log(to, "to");
      //console.log(from, "from");
      if (to.meta.index > from.meta.index) {
        //设置动画名称
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }
    },
  },
  setup(){
    useMeta({
      title: 'BanG Dream!萌え',
      htmlAttrs: {
        lang: 'en',
        amp: true,
      },
    })
  },
  name: 'App',
  components: {
    Background,
  }
};

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
nav {
  padding: 10px;
  background: #ff3b72;
}
nav #navilink {
  text-align: center;
  margin-right: 20px;
}

nav #title {
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
}

nav a {
  font-weight: bold;
  color: #000000;/*#2c3e50*/
}

nav a.router-link-exact-active {
  color: #ffffff;/*#42b983*/
}

nav h1 {
  font-size: 1.5em;
  margin: 0;
  color:#ffffff;
  text-align: left;
}

Body {
  margin: 0;
}

.slide-right-enter-active, .slide-right-leave-active, .slide-left-enter-active, .slide-left-leave-active {
  will-change: transform;
  transition: all 500ms;
  position: absolute;
}

.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
</style>
